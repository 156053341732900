var COOKIE_KEY = 'lus_cookie',
    COOKIE_AGE = 60 * 60 * 24 * 182, // half a year
    SELECTOR_TOGGLE_ALL = 'button[data-cookie-topic]',
    SELECTOR_TOGGLE_ONE = 'button[data-cookie-key]',
    SELECTOR_BUTTON_ALL = 'button[data-cookie-action="accept-all"]',
    SELECTOR_BUTTON_SEL = 'button[data-cookie-action="accept-some"]',
    SELECTOR_BUTTON_NONE = 'button[data-cookie-action="accept-none"]',
    SELECTOR_BUTTON_HIDE = 'button[data-cookie-action="hide"]',
    SELECTOR_BUTTON_SETTINGS = 'button[data-cookie-action="settings"]',
    SELECTOR_RESET = '[data-cookie-action="reset"]',
    SELECTOR_SECTION = '.lus-cookie_section',
    SELECTOR_PRE = '.lus-cookie-pre',
    CLASSNAME_ACTIVE = 'is--active';



function CookieLayer(node) {
    var toggleAll = query(SELECTOR_TOGGLE_ALL, node),
        toggleOne = query(SELECTOR_TOGGLE_ONE, node),
        buttonAll = query(SELECTOR_BUTTON_ALL),
        buttonSel = query(SELECTOR_BUTTON_SEL, node),
        buttonNone = query(SELECTOR_BUTTON_NONE, node),
        buttonHide = query(SELECTOR_BUTTON_HIDE, node),
        buttonReset = query(SELECTOR_RESET),
        cookiePre = query(SELECTOR_PRE)[0],
        buttonSettings = query(SELECTOR_BUTTON_SETTINGS);


    resetBtnAction(buttonReset);

    if (isCookieSet()) {
        //if cookie settings have been accepted - return without action
        return void 0;
    }
    // remove previously defined 'lus_' cookies and start
    // with a clean state
    unsetCookies();

    // if cookie settings have not been accepted already,
    // show the layer
    // show the small one, hide the big one
    if (cookiePre){
        cookiePre.style.display = 'block';
        node.style.display = 'none';
        if (cookiePre.dataset.index) {
        cookiePre.style.zIndex = cookiePre.dataset.index;
    }
    }
    else {
        if (hasClass('nopre', node)){
            //show main message
            node.style.display = 'block';
            //show hide button
            buttonHide[0].style.display = 'block';
        }
    }
    
    

    // get z-index value out of data and change the style 
    // on "lus-cookie" div
    if (node.dataset.index) {
        node.style.zIndex = node.dataset.index;
    }

    // add event listeners to all group buttons
    toggleAll.forEach(function (button) {
        onClick(button, function () {
            switchToggle(button);
            switchSubToggles(toggleOne, button);
        });
    });

    // add event listeners to all single buttons
    toggleOne.forEach(function (button) {
        onClick(button, function () {
            switchToggle(button);
            switchMainToggle(toggleAll, button);
        });
    });

    // add event listeners to the final 'accept all'
    // buttons
    buttonAll.forEach(function (button) {
        addClass(node.dataset.buttonclass, button);
        onClick(button, function () {
            var accepted = toggleOne.reduce(function (ls, _button) {
                switchToggle(_button, fakeRef());
                return ls.concat(_button.dataset.cookieKey);
            }, []);
            setCookies(accepted);
        });
    });

    // add event listeners to the final 'accept selected'
    // buttons
    buttonSel.forEach(function (button) {
        addClass(node.dataset.buttonclass, button);
        onClick(button, function () {
            var accepted = toggleOne.reduce(function (ls, _button) {
                return hasClass(CLASSNAME_ACTIVE, _button) ?
                    ls.concat(_button.dataset.cookieKey) :
                    ls;
            }, []);
            setCookies(accepted);
        });
    });
    
    // add event listeners to the final 'accept none'
    // buttons
    buttonNone.forEach(function (button) {
        addClass(node.dataset.buttonclass, button);
        onClick(button, function () {
            setCookies([]);
        });
    });


    buttonHide.forEach(function (button) {
        onClick(button, function () {
            node.style.display = 'none';
        });
    });
    
    buttonSettings.forEach(function (button) {
        addClass(node.dataset.buttonclass, button);
        onClick(button, function () {
            cookiePre.style.display = 'none';
            node.style.display = 'block';
        });
    });

}


function resetBtnAction(btns) {
    btns.forEach(function (button) {
        onClick(button, function () {
            unsetCookies();
            location.reload();
        });
    });
}


function switchSubToggles(allSubToggles, mainToggle) {
    findToggles(allSubToggles, closest(SELECTOR_SECTION, mainToggle)).
        forEach(function (toggle) {
            return switchToggle(toggle, mainToggle);
        });
}

function switchMainToggle(allMainToggles, subToggle) {
    findToggles(allMainToggles, closest(SELECTOR_SECTION, subToggle)).
        forEach(function (toggle) {
            if (!hasClass(CLASSNAME_ACTIVE, subToggle)) {
                return switchToggle(toggle, subToggle)
            }
            return toggle;
        });
}

function findToggles(elList, ref) {
    return elList.filter(function (el) {
        return closest(SELECTOR_SECTION, el) === ref;
    });
}

function switchToggle(toggle, reference) {
    if (reference) {
        if (hasClass(CLASSNAME_ACTIVE, reference)) {
            return addClass(CLASSNAME_ACTIVE, toggle);
        }
        return removeClass(CLASSNAME_ACTIVE, toggle);
    }
    return toggleClass(CLASSNAME_ACTIVE, toggle)
}


// ====== COOKIE GETTERS/SETTERS
function isCookieSet() {
    return document.cookie.
        indexOf(COOKIE_KEY + '_message') > -1;
}

function unsetCookies() {
    document.cookie.
        split(';').
        forEach(function (cookie) {
            var delimiterIndex = cookie.indexOf('='),
                key = delimiterIndex > -1 ?
                    cookie.slice(0, delimiterIndex) :
                    cookie;
            if (stringIncludes(COOKIE_KEY, key)) {
                expireCookie(key);
            }
        });
}

function setCookies(keys) {
    keys.forEach(defineCookie);
    defineCookie('_message');
    window.location.reload();
}

// ====== UTILITIES
function stringIncludes(pattern, string) {
    return string.indexOf(pattern) > -1;
}



function query(selector, node) {
    return Array.prototype.
        slice.call((node || document).querySelectorAll(selector));
}

function closest(selector, node) {
    return loop(function loopClosest(el, s) {
        return matches(s, el) || el === document.documentElement ?
            el :
            recur(loopClosest, el.parentNode, s);
    })(node, selector);
}

function hasClass(className, node) {
    return node.classList.contains(className);
}

function addClass(className, node) {
    if (className) {
        node.classList.add(className);
    }
    return node;
}

function removeClass(className, node) {
    if (className) {
        node.classList.remove(className);
    }
    return node;
}

function toggleClass(className, node) {
    if (className) {
        node.classList.toggle(className);
    }
    return node;
}

function matches(selector, node) {
    return node.matches ?
        node.matches(selector) :
        node.matchesSelector ?
            node.matchesSelector(selector) :
            node.msMatchesSelector ?
                node.msMatchesSelector(selector) :
                node.webkitMatchesSelector ?
                    node.webkitMatchesSelector(selector) :
                    node.mozMatchesSelector ?
                        node.mozMatchesSelector(selector) :
                        node.oMatchesSelector ?
                            node.oMatchesSelector(selector)
                            // to be honest, I highly doubt we really need
                            // this. It covers IE8 and (very) old versions
                            // of IE9. However, it might be necessary in
                            // some (very) old projects
                            :
                            loop(function loopMatches(ns, n, i) {
                                return i >= ns.length ?
                                    false :
                                    n === ns[i] ?
                                        true :
                                        recur(loopMatches, ns, n, i + 1)
                            })(document.querySelectorAll(selector), node, 0);
}

function onClick(node, handler) {
    node.addEventListener('click', handler);
    return function () {
        node.removeEventListener('click', handler);
    }
}



function expireCookie(key) {
    document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
}

function defineCookie(key) {
    document.cookie = [
        COOKIE_KEY,
        key[0] === '_' ? key : '_' + key,
        '=accepted;max-age=',
        COOKIE_AGE,
        ';samesite=lax;path=/;secure;'
    ].join('')
}

/*no secure for testing local*/
//function defineCookie(key) {
//    document.cookie = [
//        COOKIE_KEY,
//        key[0] === '_' ? key : '_' + key,
//        '=accepted;max-age=',
//        COOKIE_AGE,
//        ';samesite=lax;path=/;'
//    ].join('')
//}



function loop(f) {
    return function () {
        var retv = f.apply(null, arguments);
        while (typeof retv === 'function' && retv.RECUR) {
            retv = retv();
        }
        return retv;
    }
}

function recur(f /* arguments */) {
    var args = rest(arguments),
        recf = function () {
            return f.apply(null, args);
        };
    return Object.defineProperty(recf, 'RECUR', {
        enumerable: false,
        writable: false,
        configurable: false,
        value: true
    });
}

function rest(list) {
    return Array.prototype.slice.call(list, 1);
}

function fakeRef() {
    return {
        classList: {
            contains: function () {
                return true;
            }
        }
    }
}

export default {
    startUp: function () {
        return query('.lus-cookie').map(CookieLayer);
    }
};
